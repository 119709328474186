import { captureRemixErrorBoundaryError } from "@sentry/remix";
import type { LinksFunction } from "@remix-run/cloudflare";
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteError } from "@remix-run/react";
import "./app.css";
import Inter from "@fontsource-variable/inter/index.css?url";
import { Brandmark, PoweredByBud } from "@thisisbud/gds-ui/logo";

export const links: LinksFunction = () => [{ rel: "stylesheet", href: Inter }];

export function Layout({ children }: { children: React.ReactNode }) {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <header className="flex px-4 md:px-20 py-6 border-b border-surface-strong">
          <Brandmark size="sm" />
        </header>
        <div className="px-4 md:px-20">{children}</div>
        <footer className="my-16 flex justify-center">
          <PoweredByBud />
        </footer>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  return <div>Something went wrong</div>;
};

export default function App() {
  return <Outlet />;
}